import React from "react";
import "./firebase/config";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { AuthProvider } from "./firebase/context";
import SignIn from "./component/SignIn";
import Main from "./component/Main";

function App() {
  return (
    <AuthProvider>
      <Router>
        <Switch>
          <Route path="/auth" component={SignIn} />
          <Route path="/" component={Main} />
        </Switch>
      </Router>
    </AuthProvider>
  );
}

export default App;
