import React, { useState } from "react";
import { Button, Input, Image, Card } from "semantic-ui-react";
import QRCode from "qrcode.react";
import { auth } from "../firebase/config";

import useChalenge from "../hooks/useChalenge";

export default function Result({
  email,
  login,
  logout,
  displayName,
  photoURL,
}) {
  const [text, setText] = useState("");
  const domain = email.split("@")[1];
  let chalenge = useChalenge(email);
  // console.log(login);
  // console.log(chalenge);

  let qrlink = `https://docs.google.com/forms/d/e/1FAIpQLSejBj3_O2W_Y38Jmxe9Sc6TalzpZtMm7b7kmWI0Xcw1g93Plw/viewform?usp=pp_url&entry.1532204228=${email}`;

  const handleClick = () => {
    return (window.location = login);
  };
  const handleLogout = () => {
    auth.signOut();
  };

  let buttonState = text === chalenge;
  console.log(buttonState);
  return (
    <Card.Group centered>
      <Card fluid>
        <Card.Content>
          <Image floated="right" size="mini" src={photoURL} />
          <Card.Header>{displayName}</Card.Header>
          <Card.Meta>Welcome to ASB</Card.Meta>
          <Card.Description>
            <strong>Terms of Use </strong>

            <p>
              By accepting this agreement and accessing the wireless network,
              you acknowledge that you are of legal age, you have read and
              understood, and agree to be bound by this agreement.
            </p>
            <p>
              (*) The wireless network service is provided by the property
              owners and is completely at their discretion. Your access to the
              network may be blocked, suspended, or terminated at any time for
              any reason.
            </p>
            <p>
              (*) You agree not to use the wireless network for any purpose that
              is unlawful or otherwise prohibited and you are fully responsible
              for your use.
            </p>
            <p>
              (*) The wireless network is provided "as is" without warranties of
              any kind, either expressed or implied.
            </p>
            {domain !== "asb.ac.th" && <QRCode value={qrlink} />}
          </Card.Description>
        </Card.Content>
        {domain !== "asb.ac.th" ? (
          <Card.Content extra>
            <Input
              placeholder="Enter verification code..."
              loading
              fluid
              onChange={(e) => setText(e.target.value)}
            />
            <div className="ui two buttons">
              <Button
                basic
                color="green"
                disabled={!buttonState}
                onClick={handleClick}
              >
                Accept & Continue
              </Button>
              <Button
                basic
                color="red"
                disabled={!buttonState}
                onClick={handleLogout}
              >
                Logout
              </Button>
            </div>
          </Card.Content>
        ) : (
          <Card.Content extra>
            <div className="ui two buttons">
              <Button basic color="green" onClick={handleClick}>
                Accept & Continue
              </Button>
              <Button basic color="red" onClick={handleLogout}>
                Logout
              </Button>
            </div>
          </Card.Content>
        )}
      </Card>
    </Card.Group>
  );
}
