import sha1 from "js-sha1";
import queryString from "query-string";
import { IDLE_TIMEOUT, SECRET, SESS_TIMEOUT, SUCCESS } from "../constant";

const useAuthUrl = (search) => {
  const { xtm, sn, redirect, mac, ts, action } = queryString.parse(search);

  const sig = sha1.hex(
    ts + sn + mac + SUCCESS + SESS_TIMEOUT + IDLE_TIMEOUT + SECRET
  );

  const sigOff = sha1.hex(mac + SECRET);
  const logindURL = `${xtm}?action=${action}&ts=${ts}&success=${SUCCESS}&sess_timeout=${SESS_TIMEOUT}&idle_timeout=${IDLE_TIMEOUT}&sig=${sig}&redirect=${redirect}`;
  const logoutURL = `${xtm}?action=hotspot_logoff&mac=${mac}&sig=${sigOff}`;

  console.log(sig);
  console.log(sha1.hex("Octoberthanakorn@asb.ac.th"));
  return { logindURL, logoutURL };
};

export default useAuthUrl;
