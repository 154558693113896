import sha1 from "js-sha1";
import React from "react";

const useChalenge = (email) => {
  let dateObj = new Date();
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  let month = dateObj.getMonth();
  month = months[month];
  let textToHash = month + email;
  return sha1.hex(textToHash).substring(5, 10);
};

export default useChalenge;
