//1. import the dependencies
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

//2. Initialize app with the config vars
const app = firebase.initializeApp({
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
});

// console.log(app.options);
//3. export it for use
export const auth = app.auth();
export const db = app.firestore();

export default app;
