import React, { useContext } from "react";
import firebase from "firebase/app";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import {
  getUA,
  isMobile,
  isBrowser,
  isAndroid,
  isIOS,
} from "react-device-detect";
import { AuthContext } from "../firebase/context";
import useAuthUrl from "../hooks/useAuthUrl";
import { useLocation } from "react-router-dom";
import { Segment, Grid, Header, Icon } from "semantic-ui-react";
import Result from "./Result";
import Main from "./Main";

export default function SignIn() {
  const { user } = useContext(AuthContext);
  const location = useLocation();
  let uiOption;

  const uiIOS = [
    firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    //   firebase.auth.TwitterAuthProvider.PROVIDER_ID,
    //   firebase.auth.GithubAuthProvider.PROVIDER_ID,
    //   firebase.auth.EmailAuthProvider.PROVIDER_ID,
    //   firebase.auth.PhoneAuthProvider.PROVIDER_ID]
  ];

  const uiBrowser = [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.FacebookAuthProvider.PROVIDER_ID,

    //   firebase.auth.GithubAuthProvider.PROVIDER_ID,
    //   firebase.auth.EmailAuthProvider.PROVIDER_ID,
    //   firebase.auth.PhoneAuthProvider.PROVIDER_ID]
  ];

  if (!isBrowser) {
    uiOption = uiIOS;
  } else {
    uiOption = uiBrowser;
  }

  console.log(uiOption);

  const uiConfig = {
    // default is redirect
    // signInFlow: "popup",
    signInOptions: uiOption,

    callbacks: {
      signInSuccessWithAuthResult: function (authResult, redirectUrl) {
        // User successfully signed in.
        // Return type determines whether we continue the redirect automatically
        // or whether we leave that to developer to handle.
        return false;
      },
    },
  };

  const url = useAuthUrl(location.search);
  // console.log(url.logindURL);
  // console.log(url.logoutURL);
  // const email = "thanakorn@asb.ac.th";
  // const domain = email.split("@");
  // console.log(domain[1]);
  // console.log(user.providerData[0].providerId);
  // console.log(user.providerData[0].providerId);

  return (
    <Grid textAlign="center" style={{ height: "50vh" }} verticalAlign="middle">
      <Grid.Column style={{ maxWidth: 450 }}>
        <Header as="h2" icon textAlign="center">
          <Icon name="wifi" circular />
          <Header.Content>ASB Guest wifi authentication</Header.Content>
        </Header>
        <Segment basic padded="very">
          {/* <h1>Hello {!!user && JSON.stringify(user)}</h1> */}
          {!user && (
            <StyledFirebaseAuth
              uiConfig={uiConfig}
              firebaseAuth={firebase.auth()}
            />
          )}

          {!!user && (
            <Grid.Column style={{ maxWidth: 600 }}>
              <Result
                email={user.email}
                login={url.logindURL}
                logout={url.logoutURL}
                displayName={user.displayName}
                photoURL={user.photoURL}
              />
            </Grid.Column>
          )}
        </Segment>
      </Grid.Column>
    </Grid>
  );
}
