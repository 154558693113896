import React from "react";
import {
  getUA,
  isMobile,
  isBrowser,
  isAndroid,
  isIOS,
} from "react-device-detect";

export default function Main() {
  console.log(getUA);
  console.log(isMobile);
  console.log(isBrowser);
  return (
    <div>
      <h3>Is Browser {isBrowser.toString()}</h3>
      <h3>Is Mobile {isMobile.toString()}</h3>
      <h3>get UA {getUA}</h3>
      <h3>Is Andriod {isAndroid.toString()}</h3>
      <h3>Is IOS {isIOS.toString()}</h3>
    </div>
  );
}
